import React from "react";
import { render } from "react-dom";
import logo from './logo.svg';
import './App.css';
import { withCookies } from 'react-cookie';
import DeficiencyReport from './pages/DeficiencyReport';
import Link from '@material-ui/core/Link';


function App(props) {
  const [token, setToken] = React.useState('');

  React.useEffect(() => {
    const bootstrapAsync = () => {
      try{
        var userToken = props.cookies.get('usertoken');
        if(userToken != '' || userToken != null)
          setToken(userToken);
        else {
          props.cookies.remove('usertoken');
          window.location.href = '/';
        }
      }
      catch{
        props.cookies.remove('usertoken');
        window.location.href = '/';
      }
    };

    bootstrapAsync();
  }, ['']);

  const signOut = async () => {
    props.cookies.remove('usertoken');
    window.location.href = '/';
  }
  return (
    <div className="App">
      <header className="App-header">
        <div>TPL Street Light Survey Report</div>
        <Link href="#" onClick={signOut} className="Signout">
          Sign out
        </Link>
        {/* <a href='' onClick="" >Sign out</a> */}
      </header>
      <DeficiencyReport />
    </div>
  );
}

export default withCookies(App);
