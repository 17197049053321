class CONSTANT {
  static BASE_URL = 'http://13.233.38.147:8000';
  // static BASE_URL = 'https://tplstreetlightsurvey.intellizasolutions.com';
  static URL_SIGN_IN = CONSTANT.BASE_URL + '/rest-auth/login/';
  static URL_USER_PROFILE = CONSTANT.BASE_URL + '/rest-auth/user/';
  static URL_FETCH_STATES = CONSTANT.BASE_URL + '/api/location/state/';
  static URL_FETCH_CITIES = CONSTANT.BASE_URL + '/api/location/city/';
  static URL_FETCH_ZONES = CONSTANT.BASE_URL + '/api/location/zone/';
  static URL_FETCH_WARDS = CONSTANT.BASE_URL + '/api/location/ward/';
  static URL_GET_SP_LIST = CONSTANT.BASE_URL + '/api/switchpoint/switch-point/';
  static URL_GET_SP_SURVEY = CONSTANT.BASE_URL + '/api/switchpoint/switch-point/getsurvey/';
  static URL_GET_SP_LIST_FOR_WARD = CONSTANT.BASE_URL + '/api/switchpoint/switch-point-detail/splocationforward/';
  static URL_GET_POLES_LIST = CONSTANT.BASE_URL + '/api/poles/pole/';
  static URL_GET_POLE_SURVEY = CONSTANT.BASE_URL + '/api/poles/pole/getsurvey/';
  static URL_GET_POLES_LIST_FOR_SP = CONSTANT.BASE_URL + '/api/poles/poledetail/';
  static URL_GET_POLE_DEF_SURVEY_REPORT_FOR_SP = CONSTANT.BASE_URL + '/api/poles/pole/deficiencysurveyreport';

  static URL_AUDIT_LOG = CONSTANT.BASE_URL + '/api/audit/';

  // static GOOGLE_API_KEY = 'AIzaSyAVyjFxMQb3f3n56fHdZC4NBAuwDemyC54';
}

export default CONSTANT;