import React from "react";
import { render } from "react-dom";
import logo from '../logo.svg';
import '../App.css';
import { withCookies } from 'react-cookie';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CONSTANT from '../Constant';
import Button from '@material-ui/core/Button';
import { DataGrid } from '@material-ui/data-grid';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {
  GroupingState,
  IntegratedGrouping,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider } from '@devexpress/dx-react-grid';

import { Loading } from '../theme-sources/material-ui/components/loading';

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

function DeficiencyReport(props) {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);
  const [token, setToken] = React.useState('');
  const [states, setStates] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState(null);
  const [cities, setCities] = React.useState([]);
  const [selectedCity, setSelectedCity] = React.useState(null);
  const [zones, setZones] = React.useState([]);
  const [selectedZone, setSelectedZone] = React.useState(null);
  const [wards, setWards] = React.useState([]);
  const [selectedWard, setSelectedWard] = React.useState(null);
  const [sps, setSPs] = React.useState([]);
  const [selectedSP, setSelectedSP] = React.useState(null);
  const [inputStateValue, setInputStateValue] = React.useState('');
  const [inputCityValue, setInputCityValue] = React.useState('');
  const [inputZoneValue, setInputZoneValue] = React.useState('');
  const [inputWardValue, setInputWardValue] = React.useState('');
  const [inputSPValue, setInputSPValue] = React.useState('');
  const [poles, setPoles] = React.useState([]);
  const [grouping, setGrouping] = React.useState([]);
  const [groupingStateColumnExtensions] = React.useState([
    { columnName: 'photo', groupingEnabled: false }
  ]);
  const [imageColumns] = React.useState(['photo']);
  const [poleId, setPoleId] = React.useState(null);

  React.useEffect(() => {
    try{
      var userToken = props.cookies.get('usertoken');
      if(userToken != '' && userToken != null) {
        setToken(userToken);
      }
      else {
        props.cookies.remove('usertoken');
        window.location.href = '/';
        return;
      }
    }
    catch{
      props.cookies.remove('usertoken');
      window.location.href = '/';
      return;
    }

    fetch(CONSTANT.URL_FETCH_STATES, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Token ' + userToken
      }
    })
    .then( res => res.json() )
    .then( resp => {
      console.log('fetch states');
      console.log(resp);

      setStates(resp);
      setSelectedState(null);
      setCities([]);
      setSelectedCity(null);
      setZones([]);
      setSelectedZone(null);
      setWards([]);
      setSelectedWard(null);
    })
    .catch( error => {
      console.log('fetch states error ' + error);
    });
  }, []);

  React.useEffect(() => {
    loadCities();
  }, [selectedState]);

  React.useEffect(() => {
    loadZones();
  }, [selectedCity]);

  React.useEffect(() => {
    loadWards();
  }, [selectedZone]);

  React.useEffect(() => {
    loadSPs();
  }, [selectedWard]);

  const loadCities = () => {
    if(selectedState === null) return;
  
    fetch(CONSTANT.URL_FETCH_CITIES + '?state__id=' + selectedState.id, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Token ' + token
      }
    })
    .then( res => res.json() )
    .then( resp => {
      setCities(resp);
      setSelectedCity(null);
      setZones([]);
      setSelectedZone(null);
      setWards([]);
      setSelectedWard(null);
      setSPs([]);
      setSelectedSP(null);
    })
    .catch( error => {
      console.log('fetch cities error ' + error);
    });
  }

  const loadZones = () => {
    if(selectedCity === null) return;
    fetch(CONSTANT.URL_FETCH_ZONES + '?city__id=' + selectedCity.id, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Token ' + token
      }
    })
    .then( res => res.json() )
    .then( resp => {
      setZones(resp);
      setSelectedZone(null);
      setWards([]);
      setSelectedWard(null);
      setSPs([]);
      setSelectedSP(null);
    })
    .catch( error => {
      console.log('fetch zones error ' + error);
    });
  }

  const loadWards = () => {
    if(selectedCity === null) return;
    fetch(CONSTANT.URL_FETCH_WARDS + '?zone__id=' + selectedZone.id, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Token ' + token
      }
    })
    .then( res => res.json() )
    .then( resp => {
      setWards(resp);
      setSelectedWard(null);
      setSPs([]);
      setSelectedSP(null);
    })
    .catch( error => {
      console.log('fetch wards error ' + error);
    });
  }

  const loadSPs = () => {
    if(selectedWard === null) return;
    fetch(CONSTANT.URL_GET_SP_LIST + '?ward__id=' + selectedWard.id + '&is_deleted=False&is_testData=False', {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Token ' + token
      }
    })
    .then( res => res.json() )
    .then( resp => {
      setSPs(resp);
      setSelectedSP(null);
    })
    .catch( error => {
      console.log('fetch switchpoins error ' + error);
    });
  }

  const loadPoles = () => {
    if(selectedWard === null && poleId === null && poleId === '') return;
    setLoading(true);
    fetch(CONSTANT.URL_GET_POLE_DEF_SURVEY_REPORT_FOR_SP + '?spid=' + (selectedSP? selectedSP.component_id : '') + '&poleid=' + (poleId?poleId:''), {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : 'Token ' + token
      }
    })
    .then( res => res.json() )
    .then( resp => {
      console.log('fetch poles survey report');
      console.log(resp);
      setPoles(resp);
      setLoading(false);
    })
    .catch( error => {
      console.log('fetch switchpoins error ' + error);
    });
  }


  const ImageFormatter = ({ value }) => (
    <img src={value} style={{height: '250px'}} />
  );

  const ImageTypeProvider = props => (
    <DataTypeProvider
      formatterComponent={ImageFormatter}
      {...props}
    />
  );

  const columns = [
    { 
      name: 'survey_pole_no', 
      title: 'Survey Pole Number'
    },
    { 
      name: 'govt_pole_no', 
      title: 'Govt Pole Number'
    },
    {
      name: 'deficiency',
      title: 'Pole Deficiency'
    },
    { 
      name: 'photo', 
      title: 'Deficiency Photo'
    },
    {
      name: 'remark',
      title: 'Remark'
    }
  ];

  return (
    <div className="App">
      <div id="filters">
        <div className="Filters">
          <Autocomplete
            value={selectedState}
            onChange={(event, newValue) => {
              setSelectedState(newValue);
            }}
            inputValue={inputStateValue}
            onInputChange={(event, newInputValue) => {
              setInputStateValue(newInputValue);
            }}
            id="states"
            options={states}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="State" variant="outlined" />}
          />

          <Autocomplete
            value={selectedCity}
            onChange={(event, newValue) => {
              setSelectedCity(newValue);
              // loadZones();
            }}
            inputValue={inputCityValue}
            onInputChange={(event, newInputValue) => {
              setInputCityValue(newInputValue);
            }}
            id="cities"
            options={cities}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="City" variant="outlined" />}
          />
          
          <Autocomplete
            value={selectedZone}
            onChange={(event, newValue) => {
              setSelectedZone(newValue);
              // loadZones();
            }}
            inputValue={inputZoneValue}
            onInputChange={(event, newInputValue) => {
              setInputZoneValue(newInputValue);
            }}
            id="zones"
            options={zones}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Zone" variant="outlined" />}
          />

          <Autocomplete
            value={selectedWard}
            onChange={(event, newValue) => {
              setSelectedWard(newValue);
              // loadZones();
            }}
            inputValue={inputWardValue}
            onInputChange={(event, newInputValue) => {
              setInputWardValue(newInputValue);
            }}
            id="wards"
            options={wards}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Ward" variant="outlined" />}
          />

          <Autocomplete
            value={selectedSP}
            onChange={(event, newValue) => {
              setSelectedSP(newValue);
              console.log('selected sp');
              console.log(newValue);
              // loadZones();
            }}
            inputValue={inputSPValue}
            onInputChange={(event, newInputValue) => {
              setInputSPValue(newInputValue);
            }}
            id="switchpoins"
            options={sps}
            getOptionLabel={(option) => option.sp_name}
            style={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Switch Point" variant="outlined" />}
          />
        </div>
        <div>OR</div>
        <div className="Filter2">
          <TextField label="Survey/Government Pole Id" variant="outlined" className="PoleId" onChange={(event) => {console.log(event.target.value); setPoleId(event.target.value === '' ? null : event.target.value); console.log(poleId);}}/>
        </div>
      </div>
      <div className="SubmitButtonContainer">
        <Button
          className="SubmitButton"
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick= {() => loadPoles()}
          disabled= {selectedSP === null && (poleId === null || poleId?.length < 5)}
        >
          Fetch Poles
        </Button>
      </div>
      <div id="results" className="Results">
        {/* <DataGrid rows={poles} columns={columns} autoPageSize /> */}
        
        <Grid
          rows={poles}
          columns={columns}
        >
          <DragDropProvider />
          <GroupingState
            grouping={grouping}
            onGroupingChange={setGrouping}
            columnExtensions={groupingStateColumnExtensions}
          />
          <IntegratedGrouping />
          <ImageTypeProvider
            for={imageColumns}
          />
          <Table />
          <TableHeaderRow showGroupingControls />
          <TableGroupRow />
          <Toolbar />
          <GroupingPanel showGroupingControls />
        </Grid>
        {loading && <Loading />}
      </div>
    </div>
  );
}

export default withCookies(DeficiencyReport);
